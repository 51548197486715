import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IDocumentMetadata, IDocumentMetadataSQ, ISaveDocumentMetadata } from '../models/document.model';
import { ISR } from '../models/index.model';
import {
  Observable,
  catchError,
  concat,
  filter,
  forkJoin,
  last,
  lastValueFrom,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends BaseService {
  protected override baseURL: string = `v1/document`;
  constructor(public uS: UtilityService) {
    super();
  }

  downloadDocument = async (docCode: string, fileName?: string) => {
    return lastValueFrom(this.apiS.getFile(this.baseURL + '/download/' + docCode)).then((r) =>
      this.uS.downloader(r, fileName || docCode),
    );
  };

  deleteDocument = (docCode: string) => {
    return this.delete('/' + docCode);
  };

  deleteDocuments = (docCodes: string[]) => {
    return concat(...docCodes.map((docCode) => this.delete('/' + docCode)));
  };

  getDocumentFile = async (query: ISaveDocumentMetadata) => {
    return lastValueFrom(
      this.search(query).pipe(
        map((sr) => sr.content?.[0]),
        switchMap((document) => (document ? this.getDocumentURL(document.docCode) : of(null))),
      ),
    ).then((r) => {
      return r ? new File([r], '') : null;
    });
  };
  getDocumentLink = async (query: ISaveDocumentMetadata) =>
    this.getDocumentFile(query).then((r) => (r ? URL.createObjectURL(r) : null));
  getDocumentAsArrayBuffer = async (query: ISaveDocumentMetadata) =>
    this.getDocumentFile(query).then((r) =>
      r
        ? new Promise<ArrayBuffer>((res, rej) => {
            if (typeof FileReader !== 'undefined') {
              let reader = new FileReader();

              reader.onload = (e) => {
                res(e.target?.result as ArrayBuffer);
              };

              reader.readAsArrayBuffer(r);
            } else {
              rej(`FileReader was not found`);
            }
          })
        : null,
    );

  uploadDocument = (file: File, metadata: ISaveDocumentMetadata) => {
    // debugger;
    const fd = new FormData();

    let filename = file.name;
    if (metadata.fileName)
      filename = metadata.fileName.includes('.')
        ? metadata.fileName
        : `${metadata.fileName}.${file.name.split('.').lastItem()}`;
    fd.set('uploadParameters', JSON.stringify(metadata));
    fd.set('file', file, filename);
    return this.postFileFD<IDocumentMetadata>('', fd);
    // return this.postFile<IDocumentMetadata>('', { ...metadata, file: file });
  };

  updateDocument = (file: File, metadata: ISaveDocumentMetadata) => {
    // debugger;
    const fd = new FormData();

    let filename = file.name;
    if (metadata.fileName)
      filename = metadata.fileName.includes('.')
        ? metadata.fileName
        : `${metadata.fileName}.${file.name.split('.').lastItem()}`;
    fd.set('uploadParameters', JSON.stringify(metadata));
    fd.set('file', file, filename);
    return this.search(metadata).pipe(
      mergeMap((sr) =>
        sr.content.length > 0
          ? concat(...sr.content.map((doc) => this.deleteDocument(doc.docCode)))
          : of(null),
      ),
      last(),
      mergeMap(() => this.postFileFD<IDocumentMetadata>('', fd)),
    );

    // return this.postFileFD<IDocumentMetadata>('', fd);
    // return this.postFile<IDocumentMetadata>('', { ...metadata, file: file });
  };

  uploadDocuments = (documents: { file: File; meta: ISaveDocumentMetadata }[]) => {
    const res: {
      errorMessage?: string;
      successMeta?: IDocumentMetadata;
      reqMeta: ISaveDocumentMetadata;
    }[] = [];

    const obs = documents.length
      ? documents.map((d) =>
          this.uploadDocument(d.file, d.meta).pipe(
            map((r) => res.push({ successMeta: r, reqMeta: d.meta })),
            catchError((e) => of(res.push({ errorMessage: e || 'An error occurred', reqMeta: d.meta }))),
          ),
        )
      : [timer(100)];
    // debugger;
    return concat(...obs).pipe(map((r) => ({ result: res, errors: res.filter((x) => x.errorMessage) })));
  };

  updateDocuments = (documents: { file: File; meta: ISaveDocumentMetadata }[]) => {
    // debugger;
    const res: {
      errorMessage?: string;
      successMeta?: IDocumentMetadata;
      reqMeta: ISaveDocumentMetadata ;
    }[] = [];

    const obs = documents.length
      ? documents.map((d) =>
          this.updateDocument(d.file, d.meta).pipe(
            map((r) => res.push({ successMeta: r, reqMeta: d.meta })),
            catchError((e) => of(res.push({ errorMessage: e || 'An error occurred', reqMeta: d.meta }))),
          ),
        )
      : [timer(100)];
    // debugger;
    return concat(...obs).pipe(map((r) => ({ result: res, errors: res.filter((x) => x.errorMessage) })));
  };

  search = (query: IDocumentMetadataSQ) => this.get<ISR<IDocumentMetadata>>('/search', query);

  getDocumentURL = (docCode: string) => {
    return this.apiS.getFile<ArrayBuffer>(this.baseURL + '/download/' + docCode);
  };
}
